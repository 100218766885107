import { Controller } from "@hotwired/stimulus";
import * as Sentry from "@sentry/browser";
import { GoogleAutocompleteResolver, ResolvedAddress } from "src/google_autocomplete_resolver";

export default class extends Controller {
    static targets = ["form", "addressField", "timeField", "estimateLabel"];

    declare readonly formTarget: HTMLFormElement;
    declare readonly addressFieldTarget: HTMLInputElement;
    declare readonly timeFieldTarget: HTMLSelectElement;
    declare readonly estimateLabelTarget: HTMLElement;
    private addressData: ResolvedAddress | null = null;

    async connect(): Promise<void> {
        await google.maps.importLibrary("places");
        this.initAutocomplete();
    }

    initAutocomplete(): void {
        const addressResolver = new GoogleAutocompleteResolver();
        const autocomplete = GoogleAutocompleteResolver.defaultAutoComplete(this.addressFieldTarget);

        autocomplete.addListener("place_changed", async () => {
            this.addressData = null;
            const place = autocomplete.getPlace();
            const result = addressResolver.resolveFromAutocomplete(this.addressFieldTarget.value, place);

            if (result == null) {
                this.estimateLabelTarget.innerHTML = 'Please enter a valid address';
                return;
            } else {
                this.addressData = result;
                this.estimate();
            }
        });

        this.addressFieldTarget.addEventListener('input', () => this.resetEstimate());

        this.addressFieldTarget.addEventListener('keydown', (event) => {
            if (event.key === 'Enter') event.preventDefault();
        });

        this.addressFieldTarget.oninput = () => {
            this.addressFieldTarget.setCustomValidity('');
        };

        this.addressFieldTarget.oninvalid = () => {
            this.addressFieldTarget.setCustomValidity('Please enter an address');
        };
    }

    estimate() {
        const time = this.timeFieldTarget.value;
        const addressParams = this.addressData;
        const restaurantId = this.data.get('restaurant-id');

        if (addressParams == null || time == null || restaurantId == null) {
            this.resetEstimate();
            return;
        }

        fetch(`/partner/restaurants/${restaurantId}/deliveries/estimate_delivery_times`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': (document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content
            },
            body: JSON.stringify({ ...addressParams, time: time, restaurant_id: restaurantId })
        }).then(response => {
            return response.json();
        }).then(data => {
            const message = data.error ? data.error : `Pickup: ${data.pickup_time}, Delivery: ${data.delivery_time}`;
            this.estimateLabelTarget.innerHTML = `<span style="color: #D9381C; font-weight: bold;">Estimate:</span> ${message}`;
        }).catch(function (err) {
            Sentry.captureException(err);
        });
    }

    resetEstimate() {
        this.addressData = null;
        this.estimateLabelTarget.innerHTML = 'Complete all required fields for a delivery estimate';
    }

    async submit(event: Event): Promise<void> {
        event.preventDefault();

        if (!this.formTarget.checkValidity()) {
            this.formTarget.reportValidity();
            return;
        }

        const formData = new FormData(this.formTarget);
        const params = Object.fromEntries(formData.entries());

        Object.assign(params, this.addressData);

        const csrfToken = (document.querySelector("meta[name='csrf-token']") as HTMLMetaElement)?.content;

        const response = await fetch(this.formTarget.action, {
            method: "POST",
            credentials: "include",
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': csrfToken
            },
            body: JSON.stringify(params)
        });

        const json = await response.json();

        if (json.error) {
            this.estimateLabelTarget.innerHTML = json.error;
        } else {
            window.location.reload();
        }
    }
}
